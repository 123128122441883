@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 600px 0;
  }
}

.c-animated-background {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #f5f5f5 18%, #eeeeee 33%);
  height: 20px;
  width: 600px;
  position: absolute;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
}